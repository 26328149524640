<template>
  <fieldset class="border rounded m-2 px-2 pt-2">
    <vxe-form v-if="!readType" class="" ref="itemForm" :data="gradingTable.formData" :rules="gradingTable.rules" titleAlign="right" @submit="gradingTable.save">
        <vxe-form-item title="考核標準名稱" field="Name" :item-render="{}" span="5">
          <template #default="{ data }">
            <vxe-input type="text" v-model="data.Name" placeholder="請輸入文字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="值" field="Value" :item-render="{}" span="5">
          <template #default="{ data }">
            <vxe-input type="text" v-model="data.Value" placeholder="請輸入文字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="文字" field="TextValue" :item-render="{}" span="5">
          <template #default="{ data }">
            <vxe-input type="text" v-model="data.TextValue" placeholder="請輸入文字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="數值" field="DecimalValue" :item-render="{}" span="5">
          <template #default="{ data }">
            <vxe-input type="float" v-model="data.DecimalValue" placeholder="請輸入數字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item :item-render="{}" span="3">
          <template #default>
            <vxe-button type="submit" status="primary" :content="gradingTable.isEditing ? '修改' : '確認'">
            </vxe-button>
            <vxe-button v-if="gradingTable.isEditing" type="button" @click="gradingTable.reset" status="danger" content="取消">
            </vxe-button>
          </template>
        </vxe-form-item>
    </vxe-form>
    <vxe-table class="mb-2" ref="grid" :data="gradingTable.data" v-bind="gridOptions.props">
      <vxe-column field="Name" title="考核標準名稱">
      </vxe-column>
      <vxe-column field="Value" title="值" sortable>
      </vxe-column>
      <vxe-column field="TextValue" title="文字" sortable>
      </vxe-column>
      <vxe-column field="DecimalValue" title="數值" sortable>
      </vxe-column>
      <vxe-column v-if="!readType" title="操作" width="150">
        <template #default="{ row }">
          <div class="flex justify-content">
            <vxe-button @click="gradingTable.update(row)">編輯</vxe-button>
            <vxe-button @click="gradingTable.delete(row)">刪除</vxe-button>
          </div>
        </template>
      </vxe-column>
    </vxe-table>
    <div class="flex justify-end m-3">
      <vxe-button status="primary" @click="gradingTable.submitForm">儲存</vxe-button>
    </div>
  </fieldset>
</template>
<script lang="ts">
/* eslint-disable */
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import CloudFun, { defineComponent, ref, reactive, PropType, Condition, Operator } from "@cloudfun/core";
import { watch } from "vue";
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeTableProps } from "vxe-table";
export default defineComponent({
  name: "FlowTable",
  components: {
    SelectBox,
  },
  props: {
    data: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    aid: Number,
    visible: Boolean,
    readType: Boolean,
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const grid = ref<any>();
    const gridOptions = {
      props: {
        sortConfig: {
          multiple: true,
          defaultSort: []
        }
      } as VxeTableProps,
      insert() {
        const { row } = grid.value.insertAt({}, 0);
        grid.value.setActiveRow(row)
      }
    }

    const gradingTable = reactive({
      data: [] as any[],
      isLoading: false,
      isEditing: false,
      formData: {  } as any,
      selectedRow: null as any | null,
      // insert() {
      //   gradingTable.formData = { OutSource: false, Fee: 0, PayStatus: 0, Ordinal: 0 };
      //   gradingTable.selectedRow = null;
      //   gradingTable.isEditing = true;
      // },
      update(row: any) {
        gradingTable.formData = { ...row };
        gradingTable.selectedRow = row;
        gradingTable.isEditing = true;
      },
      delete(row: any) {
        grid.value.remove(row);
        gradingTable.reset();
      },
      submitForm() {
        emit('close', grid.value.getTableData().fullData)
      },
      reset() {
        gradingTable.formData = {} as any;
        gradingTable.formData.DecimalValue = 0;
        gradingTable.selectedRow = null;
        gradingTable.isEditing = false;
      },
      async save() {
        if(gradingTable.isEditing) {
          // 編輯確認
          Object.assign(gradingTable.selectedRow, gradingTable.formData);
        } else {
          // 新增確認
          await grid.value.insert(gradingTable.formData);
        }
        gradingTable.reset();
      },
      rules: {
        Name: [{ required: true }],
        Value: [{ required: true }],
      }
    })

    watch(() => props.visible, async(value) => {
      if(value) {
        console.log(props.data)
        if(props.data && props.data.length > 0) {
          gradingTable.data = props.data;
        } else {
          gradingTable.data = [] as any;
        }
        gradingTable.reset();
        await grid?.value?.reloadData(gradingTable.data);
      } else {
        gradingTable.data = [] as any;
        gradingTable.reset();
      }
    }, { immediate: true });

    const itemForm = ref<any>();

    return {
      gradingTable,
      grid,
      gridOptions,
      itemForm,
      model,
    }
  },
  methods: {
    getData() {
      return this.grid.getTableData().fullData;
    }
  }
})
</script>
