
/* eslint-disable */
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import CloudFun, { defineComponent, ref, reactive, PropType, Condition, Operator } from "@cloudfun/core";
import { watch } from "vue";
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeTableProps } from "vxe-table";
export default defineComponent({
  name: "FlowTable",
  components: {
    SelectBox,
  },
  props: {
    data: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    aid: Number,
    visible: Boolean,
    readType: Boolean,
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const grid = ref<any>();
    const gridOptions = {
      props: {
        sortConfig: {
          multiple: true,
          defaultSort: []
        }
      } as VxeTableProps,
      insert() {
        const { row } = grid.value.insertAt({}, 0);
        grid.value.setActiveRow(row)
      }
    }

    const gradingTable = reactive({
      data: [] as any[],
      isLoading: false,
      isEditing: false,
      formData: {  } as any,
      selectedRow: null as any | null,
      // insert() {
      //   gradingTable.formData = { OutSource: false, Fee: 0, PayStatus: 0, Ordinal: 0 };
      //   gradingTable.selectedRow = null;
      //   gradingTable.isEditing = true;
      // },
      update(row: any) {
        gradingTable.formData = { ...row };
        gradingTable.selectedRow = row;
        gradingTable.isEditing = true;
      },
      delete(row: any) {
        grid.value.remove(row);
        gradingTable.reset();
      },
      submitForm() {
        emit('close', grid.value.getTableData().fullData)
      },
      reset() {
        gradingTable.formData = {} as any;
        gradingTable.formData.DecimalValue = 0;
        gradingTable.selectedRow = null;
        gradingTable.isEditing = false;
      },
      async save() {
        if(gradingTable.isEditing) {
          // 編輯確認
          Object.assign(gradingTable.selectedRow, gradingTable.formData);
        } else {
          // 新增確認
          await grid.value.insert(gradingTable.formData);
        }
        gradingTable.reset();
      },
      rules: {
        Name: [{ required: true }],
        Value: [{ required: true }],
      }
    })

    watch(() => props.visible, async(value) => {
      if(value) {
        console.log(props.data)
        if(props.data && props.data.length > 0) {
          gradingTable.data = props.data;
        } else {
          gradingTable.data = [] as any;
        }
        gradingTable.reset();
        await grid?.value?.reloadData(gradingTable.data);
      } else {
        gradingTable.data = [] as any;
        gradingTable.reset();
      }
    }, { immediate: true });

    const itemForm = ref<any>();

    return {
      gradingTable,
      grid,
      gridOptions,
      itemForm,
      model,
    }
  },
  methods: {
    getData() {
      return this.grid.getTableData().fullData;
    }
  }
})
