/* eslint-disable */

import { commafy } from "xe-utils";

//千分位+小數三位
export function formatAmount(amount: number, digits: number = 3) {
  if(isNaN(amount)) return 0;
  var num = Number(amount);
  return num.toLocaleString(undefined, { minimumFractionDigits: digits, maximumFractionDigits: digits });
  //return commafy(num.toFixed(digits), { floor: true });

  // fixedNum: number = 1000,
  //var num = decimalPlaces(num) > 2 ? formatCeil(num, fixedNum) : num;
  //num = num % 1 !== 0 ? num.toFixed(3) : num.toFixed(0)
  //return commafy(num, { digits: digits, floor: true });
}

function formatCeil(amount: number | string, fixedNum: number = 1000) {
  var num = Number(amount);
  return num = Math.ceil(num * fixedNum) / fixedNum;
}

export function formatTotalAmount(num1: number, num2: number, fixedNum: number = 1000) {
  var integer1 = Math.round(num1 * fixedNum);
  var integer2 = Math.round(num2 * fixedNum);
  var num = (integer1 * integer2) / (fixedNum * fixedNum);
  return decimalPlaces(num) > 2 ? formatCeil(num, 100) : num;
}

export function formatCBMAmount(num1: number, num2: number, fixedNum: number = 10000) {
  var integer1 = Math.round(num1 * fixedNum);
  var integer2 = Math.round(num2 * fixedNum);
  var num = (integer1 * integer2) / (fixedNum * fixedNum);
  return num;
}

export function formatResultTotalAmount(num: number) {
  return decimalPlaces(num) > 2 ? Math.floor(num * 100) / 100 : num;
}

export function formatResultCBMAmount(num: number) {
  return decimalPlaces(num) > 4 ? Math.floor(num * 10000) / 10000 : num;
}

export function InspectionQuantity(num: number) {
  if(isNaN(num)) return 0;
  if(num < 1) return 0;  
  // 2~8   2
  // 9~15  3
  // 16~25   5
  // 26~50   8
  // 51~900   13
  // 91~150   20
  // 151~280    32
  // 281~500    50
  // 501~1200     80
  // 1201~3200   125
  // 3201~10000         200
  // 10001~35000       315
  // 35001~150000     500
  // 150001~500000    800
  // 500001~以上 1250
  if (num >= 2 && num <= 8) return 2;
  if (num >= 9 && num <= 15) return 3;
  if (num >= 16 && num <= 25) return 5;
  if (num >= 26 && num <= 50) return 8;
  if (num >= 51 && num <= 90) return 13;
  if (num >= 91 && num <= 150) return 20;
  if (num >= 151 && num <= 280) return 32;
  if (num >= 281 && num <= 500) return 50;
  if (num >= 501 && num <= 1200) return 80;
  if (num >= 1201 && num <= 3200) return 125;
  if (num >= 3201 && num <= 10000) return 200;
  if (num >= 10001 && num <= 35000) return 315;
  if (num >= 35001 && num <= 150000) return 500;
  if (num >= 150001 && num <= 500000) return 800;
  if (num >= 500001) return 1250;
  return 1;
}

function decimalPlaces(num: number) {
  const match = (''+num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
  if (!match) { return 0; }
  return Math.max(
    0,
    // Number of digits right of decimal point.
    (match[1] ? match[1].length : 0)
    // Adjust for scientific notation.
    - (match[2] ? +match[2] : 0)
  );
}

//數字轉成英文
export function numberToWord(number: number) {
    const ones = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
    const tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
    const teens = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
    const scales = ['', 'thousand', 'million', 'billion'];
  
    if (number === 0) {
      return 'zero';
    }
  
    let result = '';
  
    // 將數字轉換成三位一組的數組，從低位到高位處理每一組
    let groups = [];
    while (number > 0) {
      groups.push(number % 1000);
      number = Math.floor(number / 1000);
    }
  
    for (let i = 0; i < groups.length; i++) {
      let group = groups[i];
      let hundreds = Math.floor(group / 100);
      let tensUnits = group % 100;
      let groupWords = '';
  
      if (hundreds > 0) {
        groupWords += ones[hundreds] + ' hundred ';
      }
  
      if (tensUnits > 0) {
        if (tensUnits < 10) {
          groupWords += ones[tensUnits];
        } else if (tensUnits < 20) {
          groupWords += teens[tensUnits - 10];
        } else {
          let tensDigit = Math.floor(tensUnits / 10);
          let onesDigit = tensUnits % 10;
          groupWords += tens[tensDigit] + (onesDigit > 0 ? '-' + ones[onesDigit] : '');
        }
      }
  
      if (groupWords !== '') {
        result = groupWords + scales[i] + ' ' + result;
      }
    }
  
    return result.trim().toLowerCase().replace(/ /g,' ');
  }
