<template>
  <div v-if="!readonly" class="w-full flex xl:flex-row sm:flex-col xl:justify-end">
    <button v-if="grid && !grid.isEditing" class="button text-white bg-theme-1 shadow-md flex items-center mb-2 mr-2" @click="grid.addNewRow">
      <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
      新增
    </button>
  </div>
  <Grid ref="grid" v-bind="gridOptions"
    @addNewRow="initData"
    @removeSelectedRows="hideBatchDropDown()"
    @edit="onGridEdit"
  >
    <template #modal="{ row, submit, reset }">
      <vxe-form
        class="ml-3"
        ref="sessionForm"
        v-bind="formOptions"
        :data="row"
        @reset="reset"
        @submit="submit">
          <template #column-position="{ data }">
            <select-box v-bind="selectorOptions" v-model="data.PositionId" />
          </template>
      </vxe-form>
    </template>
    <template #modal-footer>
      <div v-if="!readonly">
        <vxe-button type="submit" status="primary" content="確認" @click="$refs.sessionForm.validate((errMap) => { if (errMap === undefined) $refs.sessionForm.dispatchEvent('submit'); })"></vxe-button>
        <vxe-button type="reset" content="重置" @click="$refs.sessionForm.dispatchEvent('reset')"></vxe-button>
      </div>
    </template>
    <template #operate="{ row }">
      <button v-if="readonly"
        class="button rounded-full mr-1 bg-blue-600 text-white" title="查看" @click.stop="grid.edit(row)">
        <FontAwesome icon="book-open" class="w-4 h-4" />
      </button>
      <div v-else class="flex justify-center">
        <button class="button rounded-full mr-1 bg-blue-600 text-white" title="編輯" @click.stop="grid.edit(row)">
          <FontAwesome icon="highlighter" class="w-4 h-4" />
        </button>
        <button type="button" class="button rounded-full bg-red-600 text-white" title="刪除"
          @click.stop="grid.remove(row)">
          <FontAwesome icon="trash" class="w-4 h-4" />
        </button>
      </div>
    </template>
  </Grid>
</template>
<script lang="ts">
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, reactive, Sorting, SortOrder, PropType } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps, VxeTableProps } from 'vxe-table'
import formatDate from 'xe-utils/toDateString'
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'
import { watch } from "vue";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
  },
  props: {
    sid: String,
    contentType: Number,
    modelValue: Boolean,
    read: Boolean,
    promises: Object as PropType<{
      /** 非同步查詢 */
      query?: (params: {
        page: number;
        pageSize: number;
        keyword?: string;
        sortings?: Sorting[];
        condition?: Condition;
      }) => Promise<{ data: any[]; totalCount: number }>;
      /** 查詢所有資料 */
      queryAll?: (params?: {
        keyword?: string;
        sortings?: Sorting[];
        condition?: Condition;
      }) => Promise<any[]>;
      /** 儲存已變更資料 */
      save?: (params: {
        insertRows?: any[];
        updateRows?: any[];
        deleteRows?: any[];
      }) => Promise<void>;
    }>
  },
  setup(props) {
    const model = CloudFun.current?.model
    const readonly = ref<any>(props.read);
    const grid = ref<any>();
    const gridOptions: GridOptions = {
      title: '文件流程資料',
      canCreate: true,
      canUpdate: true,
      canDelete: true,
      multiselect: false,
      showFooter: true,
      toolbarConfig: {
        refresh: true,
      },
      columns: [
        { field: 'Name', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Type", title: "表單類型", showHeaderOverflow: true, showOverflow: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.DocumentContentType).find(e => e.Value === cellValue)?.Name : undefined },
        { field: 'Level', title: '層級', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Position.Name', title: '職務', showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      promises: { query: props.promises?.query, queryAll: props.promises?.queryAll, save: props.promises?.save },
      modalConfig: { width: "50%", height: "450", showFooter: true },
    }

    const formOptions: VxeFormProps = {
      titleAlign: 'right',
      items: [
        {
          field: 'Name',
          title: '名稱',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字' },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'Type',
          title: '表單類型',
          span: 12,
          itemRender: { name: "$select", props: { disabled: true }, options: model ? Object.values(model.enums.DocumentContentType).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'Level',
          title: '層級',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入數字', type: 'number' },
          }
        },
        {
          field: 'PositionId',
          title: '職務',
          span: 12,
          slots: { default: "column-position" }
        },
      ],
      rules:{
        Name: [{ required: true }],
        PositionId: [{ required: true }],
        Level: [{ required: true }],
        Type: [{ required: true }],
      }
    }

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("position/find", value), // eslint-disable-line
        query: params => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return model!.dispatch("position/query", params) // eslint-disable-line
        }
      }
    };

    watch(() => props.read, async(value) => {      
      readonly.value = value;
    }, { immediate: true });

    const initData = (row: any, callback: any) => {
      row.DocumentId = props.sid;
      row.Level = 0;
      row.Type = props.contentType;
      callback();
    }

    return {
      grid,
      initData,
      gridOptions,
      formOptions,
      selectorOptions,
      readonly,
    }
  },
  methods: {
    refresh() {      
      (this.$refs.grid as any).refresh();
    },
    async onGridEdit (row: any, callback: any) {
      // const entity = row.Id ? await this.$model.dispatch('documentRecord/find', row.Id) : undefined
      // Object.assign(row, entity)
      // const statuses = (Object.values(this.$model.enums.DocumentStatus) as any[]);
      // row.BeforeStatusName = statuses.find(e => e.Value === row.BeforeStatus).Name;
      // row.AfterStatusName = statuses.find(e => e.Value === row.AfterStatus).Name;
      //   if (entity) {
      //     entity.Xml.Time = new Date(entity.Xml.Time)
      //   }
      callback()
    },
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
  }
});
</script>
