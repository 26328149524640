<template>
  <fieldset class="border rounded m-2 px-2 pt-2">
    <vxe-form v-if="!readType" class="" ref="anyForm" :data="table.formData" :rules="table.rules" titleAlign="right" @submit="table.save">
        <vxe-form-item title="元件品號" field="MaterialNumber" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="text" v-model="data.MaterialNumber" placeholder="請輸入文字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="品名" field="MaterialName" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="text" v-model="data.MaterialName" placeholder="請輸入文字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="規格" field="MaterialSpecification" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="text" v-model="data.MaterialSpecification" placeholder="請輸入文字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="單位" field="Unit" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="text" v-model="data.Unit" placeholder="請輸入文字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="材料型態" field="MaterialType" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="text" v-model="data.MaterialType" placeholder="請輸入文字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="組成用量" field="CompositionDosage" :item-render="{}" span="3">
          <template #default="{ data }">
            <vxe-input type="float" v-model="data.CompositionDosage" placeholder="請輸入數字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="底數" field="BaseQuantity" :item-render="{}" span="3">
          <template #default="{ data }">
            <vxe-input type="float" v-model="data.BaseQuantity" placeholder="請輸入數字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="耗損率" field="AttritionRate" :item-render="{}" span="3">
          <template #default="{ data }">
            <vxe-input type="float" v-model="data.AttritionRate" placeholder="請輸入數字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="客供料" field="CustomerFurnished" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-select v-model="data.CustomerFurnished" placeholder="客供料" clearable>
              <vxe-option v-for="item in TF" :key="item.value" :value="item.value" :label="`${item.label}`"></vxe-option>
            </vxe-select>
          </template>
        </vxe-form-item>
        <vxe-form-item :item-render="{}" span="3">
          <template #default>
            <vxe-button type="submit" status="primary" :content="table.isEditing ? '修改' : '確認'">
            </vxe-button>
            <vxe-button v-if="table.isEditing" type="button" @click="table.reset" status="danger" content="取消">
            </vxe-button>
          </template>
        </vxe-form-item>
    </vxe-form>
    <vxe-table class="mb-2" ref="grid" :data="table.data" v-bind="gridOptions.props">
      <vxe-column field="MaterialNumber" title="元件品號">
      </vxe-column>
      <vxe-column field="MaterialName" title="品名" sortable>
      </vxe-column>
      <vxe-column field="MaterialSpecification" title="規格" sortable>
      </vxe-column>
      <vxe-column field="Unit" title="單位">
      </vxe-column>
      <vxe-column field="CompositionDosage" title="組成用量" sortable>
      </vxe-column>
      <vxe-column field="BaseQuantity" title="底數" sortable>
      </vxe-column>
      <vxe-column field="AttritionRate" title="耗損率" sortable>
      </vxe-column>
      <vxe-column field="MaterialType" title="材料型態" sortable>
      </vxe-column>
      <vxe-column field="CustomerFurnished" title="客供料" sortable :formatter="({ cellValue }) => cellValue == true ? '是' : cellValue == false ? '否' : '不選擇'">
      </vxe-column>
      <vxe-column v-if="!readType" title="操作" width="150">
        <template #default="{ row }">
          <div class="flex justify-content">
            <vxe-button @click="table.update(row)">編輯</vxe-button>
            <vxe-button @click="table.delete(row)">刪除</vxe-button>
          </div>
        </template>
      </vxe-column>
    </vxe-table>
  </fieldset>
</template>
<script lang="ts">
/* eslint-disable */
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import CloudFun, { defineComponent, ref, reactive, PropType, Condition, Operator } from "@cloudfun/core";
import { watch } from "vue";
import { VxeTableProps, VxeFormProps } from "vxe-table";

export default defineComponent({
  name: "FlowTable",
  components: {
    SelectBox
  },
  props: {
    data: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    aid: Number,
    visible: Boolean,
    readType: Boolean,
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const grid = ref<any>();
    const gridOptions = {
      props: {
        sortConfig: {
          multiple: true,
          defaultSort: []
        }
      } as VxeTableProps,
      insert() {
        const { row } = grid.value.insertAt({}, 0);
        grid.value.setActiveRow(row)
      }
    }

    const table = reactive({
      data: [] as any[],
      isLoading: false,
      isEditing: false,
      formData: {  } as any,
      selectedRow: null as any | null,
      update(row: any) {
        table.formData = { ...row };
        table.selectedRow = row;
        table.isEditing = true;
      },
      delete(row: any) {
        grid.value.remove(row);
        table.reset();
      },
      submit() {
        emit('submit', grid.value.getTableData().fullData)
      },
      reset() {
        table.formData = {};
        table.formData.CompositionDosage = 0;
        table.formData.BaseQuantity = 0;
        table.formData.AttritionRate = 0;
        table.formData.CustomerFurnished = null;
        table.selectedRow = null;
        table.isEditing = false;
      },
      async save() {
        if(table.isEditing) {
          // 編輯確認
          Object.assign(table.selectedRow, table.formData);
        } else {
          // 新增確認
          await grid.value.insert(table.formData);
        }
        table.reset();
      },
      rules: {
        MaterialNumber: [{ required: true }],
        MaterialName: [{ required: true }],
        Unit: [{ required: true }],
      }
    })

    watch(() => props.aid, async(value) => {
      if(value) {
        console.log(props.data)
        if(props.data && props.data.length > 0) {
          table.data = props.data;
        } else {
          table.data = [] as any;
        }
        table.reset();
      } else {
        table.data = [] as any;
        table.reset();
      }
    }, { immediate: true });

    const anyForm = ref<any>();

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("position/find", value),
        query: params => {
          return model!.dispatch("position/query", params)
        }
      }
    };

    const TF = [{ label: "是", value: true }, { label: "否", value: false }]

    return {
      table,
      grid,
      gridOptions,
      anyForm,
      selectorOptions,
      model,
      TF,
    }
  },
  methods: {
    getData() {
      return this.grid.getTableData().fullData;
    }
  }
})
</script>
